import React from "react"
import { ContactSection } from "../components/ContactSection"
import { Heading } from "../components/Heading"
import { Hero } from "../components/Hero"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import { Text } from "../components/Text"
import { TipsSection } from "../components/TipsSection"

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="404: Pagina niet gevonden"
      meta={[{ name: "robots", content: "noindex" }]}
    />

    <Hero>
      <Heading diap as="h1" size={40}>
        Pagina niet gevonden
      </Heading>

      <Text size={18}>
        Oeps, je hebt een pagina gevonden die niet (meer) bestaat. Misschien heb
        je een typefout gemaakt, of een verlopen URL aangeklikt. Geen nood,
        hieronder vindt je wat leuke content!
      </Text>
    </Hero>

    <TipsSection />
    <ContactSection />
  </Layout>
)

export default NotFoundPage
